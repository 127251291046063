<template>
  <div class="join">
    <div class="login_bg">
      <img src="@/assets/images/login_bg.jpg">
    </div>
    <div class="baseBox">
      <div class="joinBox">
        <h1>{{ $t("find-id-title") }}</h1>
        <h3 style="color:#969696;margin-bottom:30px;">{{ $t("find-id-info-enter-data") }}</h3>
        <div class="form">
          <div class="flexB">
            <p>{{ $t("user-data-name") }}</p>
            <input type="text" v-model="name" />
          </div>
          <div class="flexB">
            <p>{{ $t("user-data-phone-num") }}</p>
            <input
              type="text"
              :value="phone"
              @input="bindNumber"
            />
          </div>
        </div>
        <div class="buttonWrap" style="display: flex;justify-content: center;align-items: center;">
          <button class="point large" @click="submit" style="background:#0080FF;border:0;">{{ $t("btn-find-id") }}</button>
          <router-link to
            ><button class="cancel large" @click="$router.go(-1)" style="border:1px solid #ddd; color:#969696;">
              {{ $t("btn-cancel") }}
            </button></router-link
          >
        </div>
        <div v-show="error" class="error">
          {{ $t("alert-message-not-found-user") }}
        </div>
        <p class="subText">
          {{ $t("alert-message-manager-question") }}
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import Footer from "@/components/Footer";
import { findId } from "@/api/login";
export default {
  components: { Footer },
  mixins: [format],
  data() {
    return {
      phone: "",
      name: "",
      error: false,
      accountId: "",
      device : null,
    };
  },
  mounted() {
    this.accountId = this.$route.params.accountId;
    this.device = this.$route.query.device;
  },
  watch : {
    phone(val){
      let check = /^[0-9]+$/;
      if (!check.test(val)) {
        this.phone = val.replace(/[^0-9]/g,'');
      }
    },
  },
  methods: {
    bindNumber(event){
      this.phone = event.target.value;
    },
    marsking(email) {
      let marskingSize =
        Math.floor(email.split("@")[0].split("").length / 2) - 1;

      return email.replace(
        new RegExp(".(?=.{0," + marskingSize + "}@)", "g"),
        "*"
      );
    },
    submit() {
      if (this.name == "") {
        return alert(this.$t("alert-message-check-name"));
      } else if (this.phone == "") {
        return alert(this.$t("alert-message-check-phone-num"));
      }
      this.error = false;
      let data = {
        accountId : this.accountId,
        name: this.name,
        phone: this.phone,
      };
      findId(data).then((res) => {
        if (res.data.result == 0) {
          let userId = this.marsking(res.data.data);
          var path = `/${this.accountId}/findEmailDone`;
          if(this.device != undefined && this.device != null && this.device != "") {
            path = path + "?device=" + this.device;
          }

          this.$router.push({
            path: path,
            query: { accountId: userId },
          });
        } else {
          this.error = true;
        }
      }).catch((e) => {
        e;
        this.error = true;
      });
    },
  },
};
</script>
